<template>
    <div class="matchingLibrary" v-loading="isLoading">
        <div class="header_wrapper">
            <div class="header_left el_left">
                <div class="input_item" @click="selectTheorymatching" v-if="isShowTheoryFormula">
                    <span class="header_span">理论配合比编号：</span>
                    <el-input
                        class="inputWidth1"
                        v-model="experimentFormula.theory_formula_number"
                        placeholder="请点击选择"
                        readonly
                        :disabled="is_readOnly"
                    ></el-input>
                </div>
                <div class="input_item">
                    <span class="header_span">配合比编号：</span>
                    <el-input
                        class="inputWidth1"
                        v-model="experimentFormula.experiment_formula_number"
                        placeholder="自动生成"
                        disabled
                    ></el-input>
                </div>
                <div class="input_item">
                    <span class="header_span"><span class="mustFill">配合比名称：</span></span>
                    <el-input
                        class="inputWidth1"
                        v-model="experimentFormula.experiment_formula_name"
                        placeholder="请输入内容"
                        :disabled="is_readOnly"
                    ></el-input>
                </div>
                <div class="input_item">
                    <span class="header_span"><span class="mustFill">配方类型：</span></span>
                    <el-select
                        v-model="experimentFormula.formula_type"
                        placeholder="请选择"
                        disabled
                    >
                        <el-option
                            v-for="item in formula_typeData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="input_item">
                    <span class="header_span"><span class="mustFill">场站：</span></span>
                    <el-select
                        v-model="experimentFormula.station_id"
                        placeholder="请选择"
                        :disabled="is_readOnly"
                        @change="selectStation"
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        ></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="main_wrapper">
            <div class="main_top">
                <div class="product_marking_wrapper">
                    <!-- <p>产品标示</p> -->
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">产品标示</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="table_box">
                        <table
                            class="check-set-tb"
                        >
                            <tr>
                                <th><span class="mustFill">强度等级：</span></th>
                                <td>
                                    <el-input v-model="experimentFormula.strength_grade_name" placeholder="强度等级" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th><span>抗渗等级：</span></th>
                                <td>
                                    <el-input v-model="experimentFormula.impervious_name" placeholder="抗渗等级" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th><span>抗冻等级：</span></th>
                                <td>
                                    <el-input v-model="experimentFormula.antifreeze_name" placeholder="抗冻等级" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th><span>抗折要求：</span></th>
                                <td>
                                    <el-input v-model="experimentFormula.flexural_name" placeholder="抗折要求" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th><span>特殊要求：</span></th>
                                <td>
                                    <el-input v-model="experimentFormula.special_require_name" placeholder="特殊要求" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="basic_info_wrapper">
                    <!-- <p>基本信息</p> -->
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">基本信息</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="basic_info_box">
                        <div class="grid-content bg-purple table_box">
                            <table
                                class="check-set-tb"
                            >
                                <tr>
                                    <th><span class="mustFill">浇筑方式：</span></th>
                                    <td>
                                        <el-radio-group v-model="experimentFormula.pouring_way">
                                            <el-radio :label="1" disabled>
                                                泵送
                                            </el-radio>
                                            <el-radio :label="2" disabled>
                                                非泵送
                                            </el-radio>
                                        </el-radio-group>
                                    </td>
                                    <th><span class="mustFill">坍落度：</span></th>
                                    <td style="display: flex; width: 100%; justify-content: space-between">
                                        <el-input
                                            v-model="experimentFormula.slump"
                                            style="width:1.15rem"
                                            disabled
                                        ></el-input>
                                        <i>±</i>
                                        <el-input
                                            v-model="experimentFormula.slump_flaot"
                                            style="width:1.2rem"
                                            disabled
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th><span class="mustFill">施工季节：</span></th>
                                    <td>
                                        <el-select
                                            v-model="experimentFormula.season"
                                            placeholder="请选择"
                                            disabled
                                        >
                                            <el-option
                                                v-for="item in seasonData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </td>
                                    <th><span>搅拌时间(s)：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.mixing_time"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th><span>最大粒径：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.max_particle_size"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                    <th><span>水泥规格：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.cement_specifications"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th><span>容重：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.experiment_volume_weight"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                    <th><span>水胶比：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.water_binder_ratio"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th><span>砂率(%)：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.sand_rate"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                    <th><span>技术要求：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.specification"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th><span>外加剂掺量(%)：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.admixture_dosage"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                    <th><span>胶凝材料：</span></th>
                                    <td>
                                        <el-input
                                            v-model="experimentFormula.cementing_material"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_bottom">
                <div class="main_content">
                    <div class="table_header">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width:1.3rem;">
                                        原材料分类
                                    </th>
                                    <th style="width:1.3rem;">
                                        原材料子类
                                    </th>
                                    <th style="width:1.7rem;">
                                        原材料规格
                                    </th>
                                    <th style="width:1.4rem;">
                                        试验配比用量(kg)
                                    </th>
                                    <th style="width:.8rem;">
                                        操作
                                    </th>
                                    <!-- <th></th> -->
                                    <th style="width:1rem;">
                                        原料分类
                                    </th>
                                    <th style="width:1rem;">
                                        原料子类
                                    </th>
                                    <th style="width:1rem;">
                                        原料规格
                                    </th>
                                    <th style="width:1rem;">
                                        差值
                                    </th>
                                    <th style="width:1.4rem;">
                                        试验配比用量(kg)
                                    </th>
                                    <th style="width:1.4rem;">
                                        理论配比用量(kg)
                                    </th>
                                    <th style="width:1rem;">
                                        最小值(kg)
                                    </th>
                                    <th style="width:1rem;">
                                        最大值(kg)
                                    </th>
                                    <th style="width:10px;"></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table_body">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width:1.3rem;">
                                        原材料分类
                                    </th>
                                    <th style="width:1.3rem;">
                                        原材料子类
                                    </th>
                                    <th style="width:1.7rem;">
                                        原材料规格
                                    </th>
                                    <th style="width:1.4rem;">
                                        试验配比用量(kg)
                                    </th>
                                    <th style="width:.8rem;">
                                        操作
                                    </th>
                                    <!-- <th></th> -->
                                    <th style="width:1rem;">
                                        原料分类
                                    </th>
                                    <th style="width:1rem;">
                                        原料子类
                                    </th>
                                    <th style="width:1rem;">
                                        原料规格
                                    </th>
                                    <th style="width:1rem;">
                                        差值
                                    </th>
                                    <th style="width:1.4rem;">
                                        试验配比用量(kg)
                                    </th>
                                    <th style="width:1.4rem;">
                                        理论配比用量(kg)
                                    </th>
                                    <th style="width:1rem;">
                                        最小值(kg)
                                    </th>
                                    <th style="width:1rem;">
                                        最大值(kg)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item,index) in tableData">
                                    <tr :key="index">
                                        <td style="width:5.7rem;" colspan="5">
                                            <table>
                                                <tbody>
                                                    <tbody>
                                                        <template v-for="(k,i) in item.experimentalProportioning">
                                                            <tr :key="i">
                                                                <td style="width:1.3rem;">
                                                                    <el-select
                                                                        v-model="k.stock_category_binding"
                                                                        placeholder="请选择"
                                                                        disabled
                                                                    >
                                                                        <el-option
                                                                            v-for="(z, j) in stockCategoryData"
                                                                            :key="j"
                                                                            :label="z.name"
                                                                            :value="z.code"
                                                                        >
                                                                        </el-option>
                                                                    </el-select>
                                                                </td>
                                                                <td style="width:1.3rem;">
                                                                    <el-select
                                                                        v-model="k.material_child_binding"
                                                                        placeholder="请选择"
                                                                        @change="selectRawMaterialSubclass(k)"
                                                                        @input="refreshDom"
                                                                        :disabled="is_readOnly || (item.material_child_name || '') !== ''"
                                                                    >
                                                                        <el-option
                                                                            v-for="(z, j) in k.materialChildData"
                                                                            :key="j"
                                                                            :label="z.name"
                                                                            :value="z.code"
                                                                        >
                                                                        </el-option>
                                                                    </el-select>
                                                                </td>
                                                                <td style="width:1.7rem;">
                                                                    <el-select
                                                                        v-model="k.material_binding"
                                                                        placeholder="请选择"
                                                                        @focus="materialFocus(k)"
                                                                        @change="materialChange(k)"
                                                                        @input="refreshDom"
                                                                        :disabled="is_readOnly"
                                                                    >
                                                                        <el-option
                                                                            v-for="(z, j) in k.materialData"
                                                                            :key="j"
                                                                            :label="z.name"
                                                                            :value="z.code"
                                                                        >
                                                                        </el-option>
                                                                    </el-select>
                                                                </td>
                                                                <td style="width:1.4rem;">
                                                                    <el-input
                                                                        v-model="k.experiment_quantity"
                                                                        placeholder="请输入"
                                                                        min="0"
                                                                        type="number"
                                                                        @input="refreshDom"
                                                                        @focus="proportioning(k)"
                                                                        @change="calculation(item, 'change')"
                                                                        :disabled="is_readOnly"
                                                                        @mousewheel.native.prevent
                                                                    >
                                                                    </el-input>
                                                                </td>
                                                                <td style="width:.8rem;">
                                                                    <el-button
                                                                        type="primary"
                                                                        size="medium"
                                                                        plain
                                                                        class="el-icon-plus"
                                                                        style="color:#409EFF; border: none; background: #ecf5ff"
                                                                        @click="lineAddChange(item, item.experimentalProportioning, k)"
                                                                        v-if="i == 0"
                                                                        :disabled="is_readOnly"
                                                                    >
                                                                    </el-button>
                                                                    <el-button
                                                                        type="primary"
                                                                        size="medium"
                                                                        plain
                                                                        class="el-icon-delete"
                                                                        style="color:red; border: none; background: #ecf5ff"
                                                                        v-if="i != 0"
                                                                        @click="lineDelete(item, item.experimentalProportioning, i)"
                                                                        :disabled="is_readOnly"
                                                                    >
                                                                    </el-button>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </tbody>
                                            </table>
                                        </td>


                                        <td class="table_td_border" style="width:1rem;">
                                            <div>
                                                {{ item.stock_category_name }}
                                            </div>
                                        </td>
                                        <td class="table_td_border" style="width:1rem;">
                                            <div>{{ item.material_child_name }}</div>
                                        </td>
                                        <td class="table_td_border" style="width:1rem;">
                                            <div>{{ item.material_name }}</div>
                                        </td>
                                        <td
                                            class="table_td_border"
                                            style="width:1rem;"
                                            :class="[ item.difference_quantity < 0 ? 'red' : 'green' ]"
                                        >
                                            <div>{{ item.difference_quantity }}</div>
                                        </td>
                                        <td
                                            class="table_td_border"
                                            style="width:1.4rem;"
                                            :class="{'overrun': item.experiment_quantity < item.theory_quantity_min
                                                && item.experiment_quantity > item.theory_quantity_max}"
                                        >
                                            <i class="iconfont iconyujing"></i>
                                            <div>
                                                {{ item.experiment_quantity }}
                                            </div>
                                        </td>
                                        <td class="table_td_border" style="width:1.4rem;">
                                            <div>{{ item.theory_quantity }}</div>
                                        </td>
                                        <td class="table_td_border" style="width:1rem;">
                                            <div>{{ item.theory_quantity_min }}</div>
                                        </td>
                                        <td class="table_td_border" style="width:1rem;">
                                            <div>{{ item.theory_quantity_max }}</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="header_right">
            <el-button
                type="primary"
                plain
                @click="release"
                v-if="isSave && is_publish == false && is_readOnly == false"
            >
                发布
            </el-button>
            <el-button
                type="primary"
                plain
                @click="save"
                v-if="is_readOnly == false"
            >
                保存
            </el-button>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
    // 实验配比
    name: 'matching-library',
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    computed: {},
    data() {
        return {
            // 场站数据
            stationList: [],
            // 配方类型
            formula_typeData: [
                {
                    label: '混凝土',
                    value: 0,
                },
                {
                    label: '砂浆',
                    value: 1,
                },
                {
                    label: '润泵砂浆',
                    value: 3,
                },
            ],
            // 施工季节下拉数据
            seasonData: [
                {
                    label: '春',
                    value: 1,
                },
                {
                    label: '夏',
                    value: 2,
                },
                {
                    label: '秋',
                    value: 3,
                },
                {
                    label: '冬',
                    value: 4,
                },
            ],
            // 保存实验配比基本信息
            experimentFormula: {
                // 理论配比编号
                theory_formula_number: '',
                // 试验配比编号
                experiment_formula_number: '',
                // 试验配比名称
                experiment_formula_name: '',
                // 配方类型：0 混凝土 1 砂浆 3:润泵砂浆
                formula_type: 0,
                // 强度等级
                strength_grade: '',
                // 强度等级名称
                strength_grade_name: '',
                // 抗渗等级
                impervious: '',
                // 抗渗等级名称
                impervious_name: '',
                // 抗冻等级
                antifreeze: '',
                // 抗冻等级名称
                antifreeze_name: '',
                // 抗折要求
                flexural: '',
                // 抗折要求名称
                flexural_name: '',
                // 特殊要求
                special_require: '',
                // 特殊要求选中
                select_special_require: [],
                // 特殊要求名称
                special_require_name: '',
                // 浇筑方式:1:泵送；2:非泵
                pouring_way: 1,
                // 坍落度
                slump: 0,
                // 坍落度(±)
                slump_flaot: 0,
                // 施工季节：2 夏季；4 冬季
                season: 2,
                // 搅拌时间
                mixing_time: '',
                // 最大粒径
                max_particle_size: 0,
                // 水泥规格
                cement_specifications: '',
                // 设计容重
                experiment_volume_weight: 0,
                // 水胶比
                water_binder_ratio: 0,
                // 砂率
                sand_rate: 0,
                // 技术要求
                specification: '',
                // 是否发布
                is_publish: false,
                // 管控状态：0：正常，1:管控，2：解锁
                control_status: 0,
                // 主键
                id: '',
                // 版本号
                versions: 1,
                // 场站id
                station_id: '',
                // 场站名称
                station_name: '',
            },
            // 列表数据
            tableData: [],
            // 原材料名称select数据
            stockCategoryData: [],
            // 加载状态
            isLoading: false,
            // 是否已保存
            isSave: false,
            // 是否已发布
            is_publish: false,
            // 是否只读
            is_readOnly: false,
            // 是否是复制新增
            isCopyNew: false,
            // 是否显示理论配比编号选择
            isShowTheoryFormula: false,
        };
    },
    async created() {
        // 拉取用户关联场站
        this.getStationUser();
        if (JSON.stringify(this.extr) !== '{}') {
            // rowData编辑
            if (this.extr.rowData) {
                if (this.extr.isReadOnly) {
                    this.is_readOnly = this.extr.isReadOnly;
                }
                // 初始化页面数据
                this.getInitData(this.extr.rowData.experiment_formula_number, this.extr.rowData.versions);
            }
            // copyNewData复制新增
            if (this.extr.copyNewData) {
                this.isCopyNew = true;
                this.isLoading = true;
                // 获取原材料名称数据
                this.getStockCategory(this.extr.copyNewData);
            }
        } else {
            // 获取原材料名称数据
            await this.getStockCategory();
        }

        // 判断授权版本， 高级版【AuthorizationVersion=2】显示理论配比选择
        const version = this.$takeTokenParameters('AuthorizationVersion');
        if (version === '2') {
            this.isShowTheoryFormula = true;
        }
    },
    mounted() {},
    methods: {
        // 拉取用户关联场站
        getStationUser() {
            const Uid = this.$takeTokenParameters('Uid');
            this.$axios
                .get(`/interfaceApi/baseinfo/stationmanger/get_station_user?userId=${Uid}&roleCode=J05-05`)
                .then(res => {
                    if (res.length > 0) {
                        this.stationList = res;
                        this.experimentFormula.station_id = res[0].station_id;
                        this.experimentFormula.station_name = res[0].name;
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 编辑初始化页面数据
        getInitData(experiment_formula_number, versions) {
            this.isLoading = true;
            const experimentFormulaNumber = experiment_formula_number || '';
            this.$axios
                .get(`/interfaceApi/production/experimentformula/formula_and_entity/${experimentFormulaNumber}/${versions}`)
                .then(res => {
                    // 获取原材料名称数据
                    this.getStockCategory(res);
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 初始化数据处理
        initDataProcessing(res) {
            this.experimentFormula = res.experimentFormula;
            // 是否以保存
            if (this.experimentFormula.experiment_formula_number !== '') {
                this.isSave = true;
            }
            // 是否已发布
            if (this.experimentFormula.is_publish) {
                this.is_publish = true;
            } else {
                this.is_publish = false;
            }
            // 特殊要求
            if (this.experimentFormula.special_require !== '') {
                this.experimentFormula.select_special_require = this.experimentFormula.special_require.split(',');
            } else {
                this.experimentFormula.select_special_require = [];
            }
            res.experimentFormulaEntitys.forEach(item => {
                item.stock_category_binding = item.stock_category_name;
                item.material_child_binding = item.material_child_name;
                item.material_binding = item.material_name;
                this.stockCategoryData.forEach(k => {
                    // eslint-disable-next-line max-len
                    if ((k.group_code || '') !== '' && item.stock_category === k.group_code || (k.dic_code || '') !== '' && item.stock_category_dictcode === k.dic_code) {
                        this.$set(item, 'materialChildData', k.children);
                        k.children.forEach(j => {
                            // eslint-disable-next-line max-len
                            if ((j.group_code || '') !== '' && item.material_child_code === j.group_code || (j.dic_code || '') !== '' && item.material_child_dictcode === j.dic_code) {
                                this.$set(item, 'materialData', j.children);
                            }
                        });
                    }
                });
            });

            res.experimentFormulaRelTheorys.forEach(item => {
                item.experimentalProportioning = [];
                res.experimentFormulaEntitys.forEach(k => {
                    if (
                        item.material_code !== ''
                        && k.material_code === item.material_code
                    ) {
                        item.experimentalProportioning.push(k);
                    } else if (
                        (item.material_code === '' || k.material_code === '')
                        && item.material_child_name !== ''
                        && k.material_child_name === item.material_child_name
                    ) {
                        item.experimentalProportioning.push(k);
                    } else if (
                        (item.material_code === '' || k.material_code === '')
                        && (item.material_child_name === '' || k.material_child_name === '' )
                        && item.stock_category_name !== ''
                        && k.stock_category_name === item.stock_category_name
                    ) {
                        item.experimentalProportioning.push(k);
                    }
                });
            });
            this.tableData = res.experimentFormulaRelTheorys;
            // 判断是复制新增，返回原材料下拉数据之后处理初始化数据
            if (this.isCopyNew) {
                this.experimentFormula.experiment_formula_name = '';
            }
            this.$forceUpdate();
            this.isLoading = false;
        },
        // 获取原材料名称数据
        async getStockCategory(data) {
            // this.isLoading = true;
            await this.$axios
                .get('/interfaceApi/baseinfo/business_extension/material_group_tree')
                .then(res => {
                    // 所有原材料数据
                    this.stockCategoryData = res;
                    if (data) {
                        // 初始化数据处理
                        this.initDataProcessing(data);
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择场站
        selectStation() {
            const row = this.stationList.filter(item => item.station_id === this.experimentFormula.station_id)[0];
            this.experimentFormula.station_name = row.name;
        },

        // 选择理论配比
        selectTheorymatching() {
            if (this.is_readOnly) {return;}
            this.$toast(
                {
                    title: true,
                    text: '选择理论配比',
                    type: 'eject',
                    t_url: 'tableList/index',
                    extr: {
                        code: { TableCode: 'choose_theory_formula' },
                        sureCallback: this.sureCallback,
                    },
                }
            );
        },
        // 选择理论配比回调
        async sureCallback(data) {
            // 理论配比编号
            this.experimentFormula.theory_formula_number = data.theory_formula_number;
            // 获取理论配比数据
            this.getTheoreticalData(data.theory_formula_number);
        },
        // 获取理论配比数据
        getTheoreticalData(formula_number) {
            this.$axios
                .get(`/interfaceApi/production/theoryformula/choose_theory_formula/${formula_number}`)
                .then(res => {
                    if (res) {
                        res.theoryFormula.id = this.experimentFormula.id;
                        res.theoryFormula.experiment_formula_number = this.experimentFormula.experiment_formula_number;
                        res.theoryFormula.experiment_formula_name = this.experimentFormula.experiment_formula_name;
                        res.theoryFormula.remarks = this.experimentFormula.remarks;
                        res.theoryFormula.is_publish = this.experimentFormula.is_publish;
                        res.theoryFormula.control_status = this.experimentFormula.control_status;
                        res.theoryFormula.versions = this.experimentFormula.versions;
                        res.theoryFormula.station_id = this.experimentFormula.station_id;
                        res.theoryFormula.station_name = this.experimentFormula.station_name;
                        this.experimentFormula = res.theoryFormula;
                        if (this.experimentFormula.special_require !== '') {
                            this.experimentFormula.select_special_require = this.experimentFormula.special_require.split(',');
                        } else {
                            this.experimentFormula.select_special_require = [];
                        }

                        this.tableData = res.theoryFormulaEntity;
                        this.tableData.forEach(item => {
                            item.experimentalProportioning = [{
                                stock_category_binding: JSON.parse(JSON.stringify(item.stock_category_name)),
                                stock_category: JSON.parse(JSON.stringify(item.stock_category)),
                                stock_category_dictcode: JSON.parse(JSON.stringify(item.stock_category_dictcode)),
                                stock_category_name: JSON.parse(JSON.stringify(item.stock_category_name)),

                                material_child_binding: JSON.parse(JSON.stringify(item.material_child_name)),
                                material_child_code: JSON.parse(JSON.stringify(item.material_child_code)),
                                material_child_dictcode: JSON.parse(JSON.stringify(item.material_child_dictcode)),
                                material_child_name: JSON.parse(JSON.stringify(item.material_child_name)),

                                material_binding: JSON.parse(JSON.stringify(item.material_name)),
                                material_code: JSON.parse(JSON.stringify(item.material_code)),
                                material_name: JSON.parse(JSON.stringify(item.material_name)),
                                material_bg_code: JSON.parse(JSON.stringify(item.material_bg_code)),

                                experiment_quantity: JSON.parse(JSON.stringify(item.theory_quantity)),

                                materialChildData: [],
                                materialData: [],
                            }];
                            this.stockCategoryData.forEach(k => {
                                // eslint-disable-next-line max-len
                                if ((k.group_code || '') !== '' && item.stock_category === k.group_code || k.dic_code !== '' && item.stock_category_dictcode === k.dic_code) {
                                    this.$set(item.experimentalProportioning[0], 'materialChildData', k.children);
                                    k.children.forEach(j => {
                                        // eslint-disable-next-line max-len
                                        if ((j.group_code || '') !== '' && item.material_child_code === j.group_code || j.dic_code !== '' && item.material_child_dictcode === j.dic_code) {
                                            this.$set(item.experimentalProportioning[0], 'materialData', j.children);
                                        }
                                    });
                                }
                            });
                            // 计算实验配比和差值
                            this.calculation(item);
                        });
                        // 计算基本信息（容重、水胶比、砂率、外加剂掺量、胶凝材料）
                        this.calculation_BasicInfoValue();
                        this.$forceUpdate();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // input,select编辑之后刷新dom
        refreshDom() {
            this.$forceUpdate();
        },
        // 添加实验配比
        lineAddChange(item, parentData, data) {
            const addData = {
                stock_category_binding: JSON.parse(JSON.stringify(data.stock_category_name)),
                stock_category: JSON.parse(JSON.stringify(data.stock_category)),
                stock_category_dictcode: JSON.parse(JSON.stringify(data.stock_category_dictcode)),
                stock_category_name: JSON.parse(JSON.stringify(data.stock_category_name)),

                material_child_binding: '',
                material_child_code: '',
                material_child_dictcode: '',
                material_child_name: '',

                material_binding: '',
                material_code: '',
                material_name: '',
                material_bg_code: '',

                theory_quantity: 0,

                materialChildData: JSON.parse(JSON.stringify(data.materialChildData)),
                materialData: [],
            };
            if ((item.material_child_name || '') !== '') {
                addData.material_child_binding = JSON.parse(JSON.stringify(data.material_child_binding));
                addData.material_child_code = JSON.parse(JSON.stringify(data.material_child_code));
                addData.material_child_dictcode = JSON.parse(JSON.stringify(data.material_child_dictcode));
                addData.material_child_name = JSON.parse(JSON.stringify(data.material_child_name));
                addData.materialData = JSON.parse(JSON.stringify(data.materialData));
            }
            parentData.push(addData);
            this.$forceUpdate();
        },
        // 删除实验配比
        lineDelete(row, data, index) {
            if (data[index] && data[index].id) {
                this.$axios
                    .delete('/interfaceApi/production/experimentformula/delete_formula_entity/' + data[index].id)
                    .then(res => {
                        if (res) {
                            data.splice(index, 1);
                            // 计算实验配比和差值
                            this.calculation(row, 'change');
                        } else {
                            this.$message.error('删除失败');
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                data.splice(index, 1);
                // 计算实验配比和差值
                this.calculation(row, 'change');
            }
            this.$forceUpdate();
        },
        // 选择原材料子类
        selectRawMaterialSubclass(data) {
            const row = data.materialChildData.filter(item => {
                return item.code === data.material_child_binding;
            });

            if (row.length > 0) {
                data.material_child_name = row[0].name;
                data.material_child_dictcode = row[0].dic_code;
                data.material_child_code = row[0].group_code;

                data.material_binding = '';
                data.material_code = '';
                data.material_name = '';
                data.material_bg_code = '';

                data.theory_quantity = 0;

                data.materialData = [];
            }

            this.$set(data, 'materialData', row[0].children);
            this.$forceUpdate();
        },
        // 原材料规格点击判断是否选择了原材料子类
        materialFocus(data) {
            if (data.material_child_name === '') {
                this.$message({
                    message: '请先选择原材料子类！',
                    type: 'warning',
                });
            }
        },
        // 选择原材料规格
        materialChange(data) {
            const row = data.materialData.filter(item => {
                return item.code === data.material_binding;
            });
            if (row.length > 0) {
                data.material_binding = row[0].code;
                data.material_code = row[0].dic_code;
                data.material_name = row[0].name;
                data.material_bg_code = row[0].group_code;
            }
        },
        // 配比用量编辑
        proportioning(data) {
            if (data.material_child_name === '' && data.materialChildData.length > 0) {
                this.$message({
                    message: '请先选择原材料子类！',
                    type: 'warning',
                });
            } else if (data.material_name === '' && data.materialData.length > 0) {
                this.$message({
                    message: '请先选择原材料规格！',
                    type: 'warning',
                });
            }
        },
        // 保存
        save() {
            if (this.experimentFormula.station_id === '') {
                this.$message({
                    message: '请先选择场站！',
                    type: 'warning',
                });
                return;
            }
            this.isLoading = true;
            let specialRequire = '';
            if (this.experimentFormula.select_special_require.length > 0) {
                this.experimentFormula.select_special_require.forEach(item => {
                    specialRequire += item + ',';
                });
            }
            specialRequire = specialRequire.substring(0, specialRequire.length - 1);
            this.experimentFormula.special_require = specialRequire;

            const experimentFormulaEntitys = [];
            let experimentFormulaRelTheorys = [];
            this.tableData.forEach(item => {
                experimentFormulaEntitys.push(...item.experimentalProportioning);
            });
            experimentFormulaEntitys.forEach((item, index) => {
                item.sort_num = index;
            });

            experimentFormulaRelTheorys = JSON.parse(JSON.stringify(this.tableData));

            this.$axios
                .post('/interfaceApi/production/experimentformula/save', {
                    experimentFormula: this.experimentFormula,
                    experimentFormulaEntitys: experimentFormulaEntitys,
                    experimentFormulaRelTheorys: experimentFormulaRelTheorys,
                    control_msg: '',
                })
                .then(res => {
                    if (res.is_control ) {
                        this.$alert(`${res.control_msg}`, '管控提醒', {
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            confirmButtonText: '确定',
                        });
                    }
                    // 初始化页面数据
                    this.getInitData(res.formula_number, res.versions);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.$message.success('保存成功！');
                    // 是否以保存
                    this.isSave = true;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 发布
        release() {
            this.isLoading = true;
            const experimentFormulaNumber = this.experimentFormula.experiment_formula_number || '';
            const versions = this.experimentFormula.versions || 1;
            this.$axios
                .put(`/interfaceApi/production/experimentformula/publish_formula/${experimentFormulaNumber}/${versions}`)
                .then(res => {
                    if (res.is_control ) {
                        this.$alert(`${res.control_msg}`, '管控提醒', {
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            confirmButtonText: '确定',
                        });
                    }
                    this.$message.success('发布成功！');
                    // 初始化页面数据
                    this.getInitData(experimentFormulaNumber, versions);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 计算实验配比和差值
        calculation(data, type) {
            let nub = 0;
            data.experimentalProportioning.forEach(item => {
                if (!item.experiment_quantity) {
                    item.experiment_quantity = 0;
                }
                nub += Number(item.experiment_quantity);
            });
            data.experiment_quantity = Number(nub.toFixed(2));
            if (!data.theory_quantity) {
                data.theory_quantity = 0;
            }
            data.difference_quantity = NP.minus(Number(data.theory_quantity), Number(data.experiment_quantity));
            if (type === 'change') {
                this.calculation_BasicInfoValue();
            }
            this.$forceUpdate();
        },
        // 计算基本信息（容重、水胶比、砂率、外加剂掺量、胶凝材料）
        calculation_BasicInfoValue() {
            let experiment_volume_weight = 0; // 容重
            let fineAggregate = 0; // 细骨料
            let coarseAggregate = 0; // 粗骨料
            let water = 0; // 水
            let powder = 0; // 粉料
            let cement = 0; // 水泥
            let admixtures = 0; // 掺合料（分类，字典值205-开头的）
            let powderyExpansiveAgent = 0; // 粉状膨胀剂（规格：外加剂-膨胀剂-粉状膨胀剂）
            let admixture = 0; // 外加剂(排除粉状膨胀剂)

            const rowData = [];
            this.tableData.forEach(item => {
                rowData.push(...item.experimentalProportioning);
            });
            rowData.forEach(k => {
                experiment_volume_weight += Number(Number(k.experiment_quantity || 0).toFixed(2)) * 100;
                if (k.stock_category_dictcode === '203') {
                    fineAggregate += Number(k.experiment_quantity);
                }
                if (k.stock_category_dictcode === '204') {
                    coarseAggregate += Number(k.experiment_quantity);
                }
                if (k.stock_category_dictcode === '207') {
                    water += Number(k.experiment_quantity);
                }
                // eslint-disable-next-line max-len
                if (['202', '205-FHL', '205-GHJ', '205-FMH', '205-GH0', '205-GZF', '205-KZF', '205-LZF', '205-SHF'].indexOf(k.stock_category_dictcode) > -1) {
                    powder += Number(k.experiment_quantity);
                }
                if (k.stock_category_dictcode === '202') {
                    cement = NP.plus(cement, k.experiment_quantity || 0);
                }

                if (k.stock_category_dictcode.indexOf('205-') > -1) {
                    admixtures = NP.plus(admixtures, k.experiment_quantity || 0);
                }

                if (k.stock_category_dictcode === '208') {
                    if (k.material_child_dictcode === '208-PZJ' && k.material_code === '208-PZJ-01') {
                        powderyExpansiveAgent = k.experiment_quantity;
                    }
                    if (k.material_code !== '208-PZJ-01') {
                        admixture = NP.plus(admixture, k.experiment_quantity || 0);
                    }
                }

            });
            // 砂率
            if (fineAggregate > 0) {
                // eslint-disable-next-line max-len
                this.experimentFormula.sand_rate = Number(NP.times(NP.divide(fineAggregate, NP.plus(fineAggregate, coarseAggregate)), 100)).toFixed(2);
            } else {
                this.experimentFormula.sand_rate = 0;
            }

            // 水胶比
            if (water > 0 && powder > 0) {
                this.experimentFormula.water_binder_ratio = NP.divide(water, powder).toFixed(2);
            } else {
                this.experimentFormula.water_binder_ratio = 0;
            }

            // 容重
            this.experimentFormula.experiment_volume_weight = Number(NP.divide(experiment_volume_weight, 100)).toFixed(2);

            // 胶凝材料
            this.experimentFormula.cementing_material = Number(NP.plus(cement, admixtures, powderyExpansiveAgent));

            // 外加剂掺量
            if (this.experimentFormula.cementing_material) {
                // eslint-disable-next-line max-len
                this.experimentFormula.admixture_dosage = Number(NP.times(NP.divide(admixture, this.experimentFormula.cementing_material), 100)).toFixed(2);
            } else if (admixture > 0) {
                this.experimentFormula.admixture_dosage = 100;
            } else {
                this.experimentFormula.admixture_dosage = 0;
            }
        },
    },
};
</script>

<style lang="stylus">
.main_top
    .table_box
        border 1px solid #D7D7D7
    table
        width 100%;
        box-sizing border-box
        border-collapse collapse
        th,td
            border 2px solid #ffffff
            font-size 0.14rem
            line-height 0.4rem
            font-weight 400
            background #F8F8F8
            border-bottom none !important
        th
            width .6rem
            text-align right
        td
            width 2.4rem
            color #333;
            background #fff;
            .readonly_input
                >>> .el-input__inner
                    background #F8F8F8  !important
            >>> .el-radio-group
                white-space nowrap
                .el-radio
                    margin-right 0.15rem
            >>> .el-input__inner
                padding-left .05rem
            >>> .is-disabled .el-input__inner
                color #333;
                background-color #F8F8F8
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none;
input[type="number"]
    -moz-appearance: textfield;
.matchingLibrary
    width 100%
    background #fff
    height 100%
    display flex
    flex-direction column
    padding .1rem
    .el-radio__input
        &.is-disabled
            &+span.el-radio__label
                color #606266
    .el-input
        &.is-disabled
            .el-input__inner
                color #606266
    .el-dialog
        height 60% !important
    .el-table__row
        td
            padding 0
            height 0.34rem
    .el-button
        margin-left 0.15rem
        width 0.6rem
        height 0.34rem
        line-height 0.34rem
        padding 0
        font-size 0.16rem
    .el-dialog__wrapper
        height 100% !important
    .header_wrapper
        width 100%
        background #ffffff
        overflow hidden
        border 1px solid #D7D7D7
        .inputWidth1
            width 1.8rem
        .inputWidth2
            width 1.4rem
    .header_left
        width 100%
        display flex
        .input_item
            float left
            margin-right 0.02rem
            display flex
            width 20%
            font-size 0.14rem
            padding .02rem
            background #fff
            .header_span
                font-size 0.16rem
                display inline-block
                width 50%
                display flex
                align-items center
                justify-content end
                background #F8F8F8
        .el-input__inner
            height 0.36rem
            line-height 0.36rem
    .main_wrapper
        height calc(100% - 0.6rem)
        .main_top
            height 3.2rem
            display flex
            .product_marking_wrapper
                width 45%
                height 100%
                background #ffffff
                padding 0.1rem .1rem .1rem 0
                .table_box
                    padding-bottom .43rem
                p
                    font-size 0.18rem
                    border-left 0.04rem solid #1577D2
                    padding-left 0.1rem
                    font-weight 800
                ul
                    li
                        line-height 0.5rem
                        >span
                            padding-left 0.1rem
                            font-size 0.16rem
                        .el-input
                            width calc(100% - 1rem)
            .basic_info_wrapper
                width calc(55%)
                height 100%
                background #ffffff
                padding 0.1rem 0
                .basic_info_box
                    display flex
                    justify-content space-between
                    th
                        width 1.2rem !important
                    >div
                        width 100%
                p
                    font-size 0.18rem
                    border-left 0.04rem solid #1577D2
                    padding-left 0.1rem
                    font-weight 800
                ul
                    li
                        display flex
                        line-height 0.43rem
                        .item_title
                            font-size 0.16rem
                            width 1.6rem
                        .el-radio-group
                            .el-radio
                                line-height 0.43rem
                        .el-select
                            width 2.5rem
                        .el-input
                            width 2.5rem
                        >span
                            padding-left 0.1rem
        .main_bottom
            width 100%
            height calc(100% - 3rem)
            background #ffffff
            padding 0.2rem 0
            .main_content
                width 100%
                height 100%
                position relative
                border 1px solid #EBEEF5
                .table_header,
                .table_body
                    width 100%
                    table
                        width 100%
                        border-collapse collapse
                        th,td
                            font-size 0.14rem
                            text-align center
                            height 0.34rem
                        .table_td_border
                            padding .02rem
                            div
                                width 100%
                                height 100%
                                display flex
                                justify-content center
                                align-items center
                                border 1px solid #D7D7D7
                        th
                            font-size 0.14rem
                            background rgb(237, 240, 245)
                            color rgb(2, 39, 130)
                            border-right 1px solid #EBEEF5
                            border-bottom 1px solid #EBEEF5
                            &::last-child
                                border-right none
                .table_header
                    position absolute
                    top 0
                    left 0
                    z-index 9
                .table_body
                    max-height 100%
                    overflow-y scroll
                    td
                        border-right 1px solid #EBEEF5
                        border-bottom 1px solid #EBEEF5
                        &:last-child
                            border-right none
                        .el-button
                            width auto
                            height auto
                            line-height initial
                            margin 0.05rem
                            padding .03rem
                            font-size 12px
                            font-weight 600
                            color #409EFF
                            background none
                        >i
                            display none
                        &.red
                            color red
                        &.green
                            color #30fa3e
                        &.overrun
                            color red
                            div
                                i
                                    font-size 0.14rem
                                    vertical-align top
                                    display inline-block
    .header_right
        height .55rem
        display flex
        justify-content center
        align-items center
</style>
<style  lang="stylus" scoped>
    >>> .el-icon-arrow-up:before
        content: "\e78f" !important;
    >>> .el-input__suffix
        background #EFF4FF !important
        right 1px !important
    // .main_wrapper
    >>> .el-input__suffix
        height 91% !important
        top 1px !important
</style>